import { useState } from "react";
import { useZusDialogStore } from "../../../zustand/store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { translatePrefix } from "./type";
import { useTranslate } from "react-admin";
import { DialogContentText } from "../../../components/MuiGenerals";
import { customSx } from "../../../utils/styling";
import { postAddressList } from "../../../api/signer";
import { useAppDispatch } from "../../../reducer/hooks";
import { alerting } from "../../../reducer/alerterSlice";

const initState = { name: "", text: "" };

const AddressImportDialog = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const dispatch = useAppDispatch();
  const zusDialog = useZusDialogStore();
  const [fileInfo, setFileInfo] = useState(initState);
  const onUploadAddress = async (event: any) => {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      if (e)
        setFileInfo({
          name: event.target.files[0].name,
          text: e.target.result,
        });
    };
    reader.readAsText(event.target.files[0]);
  };
  //
  const confirmUploadDoc = async () => {
    let objectText = JSON.parse(fileInfo.text);
    if (objectText && objectText.length) {
      let importResult = true;
      for (let index = 0; index < objectText.length; index++) {
        const element = objectText[index];
        const { chain_type, chain_id } = element;
        const dataObj = {
          signature: element.signature,
          wallet_type: element.wallet_type,
          wallet_version: element.wallet_version,
          wallet_pubkey: element.wallet_pubkey,
          addresses: element.addresses,
        };
        const objectToDb = {
          chain_id: chain_id,
          chain_type: chain_type,
          data: {},
        };
        Object.assign(objectToDb.data, dataObj);
        const res = await postAddressList({ ...objectToDb });
        if (!res) {
          importResult = false;
        }
      }
      if (importResult) {
        dispatch(alerting("success", t("import_success")));
        setFileInfo(initState);
        zusDialog.close();
      }
    }
  };

  const handleCancel = () => {
    zusDialog.close();
    setFileInfo(initState);
  };
  return (
    <>
      <Dialog
        open={zusDialog.match("address_upload_dialog")}
        onClose={handleCancel}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title"> {t("uploadAddress")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("import")} :
            <Button color="secondary" variant="contained" component="label">
              {t("click_here_import")}
              <input
                hidden
                accept={".txt"}
                type="file"
                onChange={onUploadAddress}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
              />
            </Button>
            {fileInfo.name !== "" && <span> {fileInfo.name}</span>}
          </DialogContentText>
          <br />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={confirmUploadDoc}
          >
            {t("confirm")}
          </Button>
          <Button variant="contained" color="error" onClick={handleCancel}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressImportDialog;
