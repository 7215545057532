import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../reducer/hooks";
import { selectPermissions } from "../reducer/profileSlice";
import { setView } from "../reducer/stuffSlice";
import { FeatureCodes } from "../utils/constant";

//###add-new-page
const { assetManagement, walletManagement, report, tools, ledger } =
  FeatureCodes;
export const routeList: { [key: string]: string[] } = [
  ["homepage", "", ""],
  ["accountSetting", "account_setting", ""],
  ["assetList", "asset_list", assetManagement.AssetList.prefix],
  ["batchSweep", "batch_sweep", assetManagement.BatchSweep.prefix],
  [
    "sweepRequestRecord",
    "sweep_request_record",
    assetManagement.SweepRequestRecord.prefix,
  ],
  ["batchWithdraw", "batch_withdraw", assetManagement.BatchWithdraw.prefix],
  [
    "withdrawRequestRecord",
    "withdraw_request_record",
    assetManagement.WithdrawRequestRecord.prefix,
  ],
  [
    "operationRecords",
    "operation_records",
    assetManagement.OperationRecords.prefix,
  ],
  [
    "operationTransactionRecords",
    "operation_transaction_records",
    assetManagement.OperationTransactionRecords.prefix,
  ],
  [
    "merchantOperation",
    "merchant_operation",
    assetManagement.MerchantOperation.prefix,
  ],
  [
    "withdrawSetting",
    "withdraw_setting",
    assetManagement.WithdrawSetting.prefix,
  ],
  [
    "withdrawApprovalSetting",
    "withdraw_approval_setting",
    assetManagement.WithdrawApprovalSetting.prefix,
  ],
  [
    "withdrawApprovalCheckingTimeSetting",
    "withdraw_approval_checking_time_setting",
    assetManagement.WithdrawApprovalCheckingTimeSetting.prefix,
  ],
  [
    "walletManagement",
    "wallet_management",
    walletManagement.WalletManagement.prefix,
  ],
  ["walletBalance", "wallet_balance", walletManagement.WalletBalance.prefix],
  [
    "totalWalletBalance",
    "total_wallet_balance",
    walletManagement.TotalBalanceOverview.prefix,
  ],
  [
    "walletBalanceAdjustment",
    "wallet_balance_adjustment",
    walletManagement.WalletBalanceAdjustment.prefix,
  ],
  ["merchantReport", "merchant_report", report.MerchantReport.prefix],
  ["clientReport", "client_report", report.ClientReport.prefix],
  ["transactionReport", "transaction_report", report.AllTransactions.prefix],
  [
    "depositAndWithdrawalStatistics",
    "deposit_and_withdrawal_statistics",
    report.DepositAndWithdrawalStatistics.prefix,
  ],
  ["messageManagement", "message_management", report.MessageManagement.prefix],
  ["blockRescan", "block_rescan", tools.Rescan.prefix],
  ["reBroadcast", "re_broadcast", tools.ReBroadcast.prefix],
  ["reSubmit", "re_submit", tools.Resubmit.prefix],
  ["yubiKeyRegister", "yubi_key_register", tools.YubiKeyRegister.prefix],
  [
    "userApprovalManagement",
    "user_approval_management",
    tools.UserApprovalManagement.prefix,
  ],
  [
    "balanceCheckpoint",
    "balance_checkpoint",
    tools.OnChainBalanceCheckpoint.prefix,
  ],
  ["addressGeneration", "address_generation", tools.AddressGeneration.prefix], //temporary remove
  ["utxoToolManagement", "utxo_tool_management", tools.UTXOTool.prefix],
  ["nonceTool", "nonce_tool", tools.NonceTool.prefix],
  ["auditLog", "audit_log", tools.AuditLog.prefix],
  [
    "walletManagerDepositRecord",
    "wallet_manager_deposit_record",
    ledger.WalletManagerDepositRecord.prefix,
  ],
  ["withdrawRequest", "withdraw_request", ledger.WithdrawRequest.prefix],
  ["ledgerFreezeRequest", "ledger_freeze_request", ledger.FreezeRequest.prefix],
  ["ledgerFrozenFund", "ledger_frozen_fund", ledger.FrozenFund.prefix],
  ["transferRequest", "transfer_request", ledger.TransferRequest.prefix],
  ["clientManagement", "client_management", ledger.ClientManagement.prefix],
  ["clientBalance", "client_balance", ledger.ClientBalance.prefix],
  [
    "ledgerTransactionRecord",
    "ledger_transaction_record",
    ledger.LedgerTransactionRecord.prefix,
  ],
].reduce((acc, [key, snakeCase, premission]) => {
  return { ...acc, [key]: [snakeCase, key, premission] };
}, {});
export default function useRoutePermission() {
  const pathname = useLocation().pathname.replace("/", "");
  const dispatch = useAppDispatch();
  const permissionList = useAppSelector(selectPermissions);
  const hasPermission = (key: string) =>
    permissionList.includes(String(key).toLowerCase());
  const viewPermission = getViewPermission(pathname);
  useEffect(() => {
    dispatch(setView(viewPermission));
  }, [viewPermission]);
  const hasRoutePermission = (key: string) => {
    if (key === "homepage" || key === "accountSetting") return true;
    const [_, __, permission] = routeList[key] || [];
    return hasPermission(permission);
  };
  return { hasRoutePermission, viewPermission };
}
export function getViewPermission(pathname: string) {
  const [permission] = Object.values(routeList).find(
    ([_, path]) => path === pathname
  ) || ["homepage"];
  return permission;
}
